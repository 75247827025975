import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useTransition, animated } from "react-spring";

import Button, { InputType } from "@/components/controls/button";

import useClientApi from "@/hooks/use-client-api";

const FormStyled = styled.form`
  position: relative;
`;

const InputField = styled.div`
  text-align: left;
`;

const Flex = styled.div`
  border-bottom: ${(props) => props.theme.Color.PrimaryColor} solid 1px;
  height: 30px;

  display: flex;
  align-items: center;
`;

const Control = styled.div`
  padding-right: ${(props) => props.theme.Padding}px;
  height: 100%;

  flex: 1;
  display: flex;
`;

const InputLabel = styled.label`
  color: ${(props) => props.theme.Color.SecondaryColor};
  font-size: 14px;
  white-space: nowrap;
`;

const Input = styled.input`
  ${(props) => css`
    ${props.theme.AppearanceNone()}
    ${props.theme.FontFamily()}

    color: ${props.theme.Color.PrimaryColor};
    box-sizing: border-box;
    font-size: 16px;
    outline: none;
    flex: 1;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    &::placeholder {
      color: ${props.theme.Color.SecondaryColor};
    }
  `}
`;

const ErrorMessage = styled(animated.div)`
  ${(props) => css`
    ${props.theme.FontHeader()}
    background-color: ${props.theme.Color.Pink};
    overflow: hidden;
    padding-top: 0.2em;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

type FormInputProps = React.HTMLProps<HTMLInputElement> & {
  name?: string;
  label?: string;
  type?: string;
  value?: string;
  onChange?: any;
  initialValue?: string;
  minLength?: number;
  error?: any;
  attachGenericError?: boolean;
};

const FormInput = ({
  name,
  label,
  type,
  value,
  onChange,
  initialValue,
  minLength,
  error,
  ...props
}: FormInputProps) => {
  const transition = useTransition(error ? error.message : null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const inputComponent = (
    <Input
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      minLength={minLength}
      {...(props as any)}
    />
  );

  if (type === "hidden") {
    return inputComponent;
  }

  return (
    <InputField>
      <Flex>
        <Control>{inputComponent}</Control>
        <InputLabel htmlFor={name}>{label}</InputLabel>
      </Flex>
      {transition(
        (props, item) =>
          item && <ErrorMessage style={props as any}>{item}</ErrorMessage>
      )}
    </InputField>
  );
};

const FormButton = ({ text, ...rest }) => (
  <Button type={InputType.SUBMIT} {...rest} cta>
    {text}
  </Button>
);

const getInitialFormData = (children) => {
  const updatedData = {};

  const traverseChildren = (child) => {
    if (child && child.type === FormInput) {
      updatedData[child.props.name] = child.props.initialValue || "";
    } else if (child && child.props && child.props.children) {
      React.Children.forEach(child.props.children, traverseChildren);
    }
  };
  React.Children.forEach(children, traverseChildren);
  return updatedData;
};

type FormProps = {
  method?: string;
  action?: string;
  children?: React.ReactNode;
  onPreSubmit?: any;
  onPostSubmit?: any;
  defaultFailureMessage?: string;
  checkIsValid?: any;
  className?: string;
};

const Form = ({
  method = "POST",
  children,
  onPreSubmit,
  onPostSubmit,
  action,
  defaultFailureMessage,
  checkIsValid,
  className,
}: FormProps) => {
  const clientApi = useClientApi();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [failedMessage, setFailedMessage] = useState(null);
  const [data, setData] = useState(getInitialFormData(children));

  const onChange = (event) => {
    const { name, value } = event.target || event;

    setData({
      ...data,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setFailedMessage(null);

    let submissionData = data;
    if (onPreSubmit) {
      submissionData = onPreSubmit();
    }

    try {
      const { isChangingRoute } = await clientApi(action, {
        method: method,
        data: submissionData,
      });

      if (onPostSubmit) {
        onPostSubmit();
      }

      if (!isChangingRoute) {
        setIsSubmitting(false);
        setData(getInitialFormData(children));
      }
    } catch (err) {
      console.log("clientApi failed", err);
      const { message = defaultFailureMessage, inputKey } = err;

      setIsSubmitting(false);
      setFailedMessage({ message, inputKey });
    }
  };

  /*
    Go over children and grandchildren that are a FormInput and insert the following fields:
      - value: the current value for this field of this.state.data
      - onChange: the onChange handler
  */
  const renderChild = (child) => {
    const displayedFailureMessage = failedMessage;
    const isValid = checkIsValid ? checkIsValid(data) : true;

    if (child && child.type === FormInput) {
      const childName = child.props.name;

      let error = null;
      if (
        displayedFailureMessage &&
        (displayedFailureMessage.inputKey === childName ||
          (!displayedFailureMessage.inputKey && child.props.attachGenericError))
      ) {
        error = displayedFailureMessage;
      }

      if (data) {
        const newElement = React.cloneElement(child, {
          value: data[childName],
          disabled: isSubmitting,
          onChange: onChange,
          error,
        });
        return newElement;
      }
    } else if (child && child.type === FormButton) {
      const el = React.cloneElement(child, {
        disabled: isSubmitting || !isValid,
        isLoading: isSubmitting,
      });
      return el;
    } else if (child && child.props && child.props.children) {
      try {
        const singleChild = React.Children.only(child.props.children);
        return React.cloneElement(child, child.props, renderChild(singleChild));
      } catch (err) {
        return React.cloneElement(
          child,
          child.props,
          React.Children.map(child.props.children, renderChild)
        );
      }
    }
    return child;
  };

  return (
    <FormStyled {...{ method, action, className }} onSubmit={onSubmit}>
      {React.Children.map(children, renderChild)}
    </FormStyled>
  );
};

Form.Input = FormInput;
Form.Button = FormButton;

export default Form;

import React from "react";
import Link, { LinkProps } from "next/link";
import styled, { css } from "styled-components";
import { readableColor } from "polished";

import useTheme from "../../hooks/use-theme";

import Form from "../form";
import Loading from "./loading";
import ControlAnimation from "./control-animation";

export enum ButtonVariant {
  Text,

  // Default - looks like a solid button
  Contained,

  // You should not be using these variants in any new files
  Icon,
  ContainedIcon,
  GrowableIcon,
  GiantText,
}

export enum InputType {
  SUBMIT,
}

const TextStyles = ({ useUnderline }) => {
  return css`
    ${(props) => props.theme.AppearanceNone()}
    ${(props) => props.theme.FontFamily()}

    color: inherit;
    cursor: pointer;
    font-size: inherit;

    ${useUnderline && `text-decoration: underline;`}
  `;
};

type TextProps = {
  $useUnderline?: boolean;
};

const ButtonAsText = styled.button<TextProps>`
  ${(props) => TextStyles({ useUnderline: props.$useUnderline })}
`;

const Text = styled.div<TextProps>`
  ${(props) => TextStyles({ useUnderline: props.$useUnderline })}
`;

const ContainedStyles = () => {
  return css`
    box-sizing: border-box;
    border-radius: ${(props) => props.theme.ControlHeight}px;
    color: inherit;
    cursor: inherit;
    display: inline-block;
    margin: 0 auto;
    position: relative;
    text-transform: uppercase;
    user-select: none;
    white-space: pre;

    /* Any bigger on iphone, and it pushes all of the content to the left */
    max-width: 270px;

    height: ${(props) => props.theme.ControlHeight}px;

    &:disabled {
      pointer-events: none;
    }
  `;
};

const ContainedIconStyles = (width) => {
  return css`
    margin: 0;
    width: ${width}px;

    & ${ContainedText} {
      padding: 0;
    }
  `;
};

const A = styled.a`
  /* add inline-block display so the button does not stretch the full width of the page */
  display: inline-block;
`;

const LoadingPosition = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type ContainedProps = {
  $width?: number;
  type?: string;
  $disabledOpacity?: number;
};
const ContainedAnimationStyled = styled(ControlAnimation)<ContainedProps>`
  ${(props) => css`
    ${ContainedStyles()}
    ${props.$width && ContainedIconStyles(props.$width)}

    opacity: ${props.$disabledOpacity};

    /* The buttons are adding bottom spacing - i.e., source icons */
    vertical-align: top;
  `}
`;

type ContainedIconProps = {
  $size?: number;
};
const ContainedIconAnimation = styled(ControlAnimation)<ContainedIconProps>`
  ${(props) =>
    css`
      box-sizing: border-box;
      border-radius: 50%;
      padding: ${props.$size > 60
        ? props.theme.Padding * 2
        : props.theme.Padding}px;
      width: ${props.$size}px;
      height: ${props.$size}px;

      display: flex;
      justify-content: center;
    `}
`;

const InnerButton = styled.button<ContainedProps>`
  /* These button styles need to fill the space of its parent so the whole visual display of a button is clickable */
  ${(props) => props.theme.AppearanceNone()}
  color: inherit;
  cursor: inherit;

  /* So that loading spinner stays within the button */
  position: relative;
  text-transform: inherit;
  width: 100%;
  height: 100%;

  /* font-size on svg icons makes them jump on mouseout */
  ${(props) =>
    props.$width
      ? ContainedIconStyles(props.$width)
      : css`
          font-size: inherit;
        `}
`;

const FormStyled = styled(Form)`
  height: 100%;
`;

const NoStylesButton = styled.button`
  ${(props) => props.theme.AppearanceNone()}
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: inherit;
  vertical-align: top;
`;

export const ContainedText = styled.span`
  ${(props) => css`
    ${props.theme.FontHeader()}
    ${props.theme.LineClamp(1)}

    padding: 0.2rem ${props.theme.Padding * 2}px 0;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export type ButtonProps = {
  variant?: ButtonVariant;
  type?: InputType;
  onClick?: any;
  href?: LinkProps["href"] | string;
  target?: string;
  rel?: string;
  isActive?: boolean;
  isLoading?: boolean;
  action?: string;
  cta?: boolean;
  externalLink?: boolean;
  download?: string;
  disabled?: boolean;
  className?: any;

  // Width can be used to specify contained styles only
  width?: number;

  // You should not be using these props in any new files
  children?: React.ReactNode;
  bgColor?: string;
  changeBgColor?: boolean;
  changeColor?: boolean;
  color?: string;
};
const Button = ({
  variant = ButtonVariant.Contained,
  type,

  onClick,
  href,
  target,
  rel,
  isActive,
  isLoading,
  action,
  cta,
  externalLink,
  download,
  disabled,
  className,
  width,

  // You should not be using these props in any new files
  children,
  bgColor,
  changeBgColor = true,
  changeColor,
  color,
}: ButtonProps) => {
  const theme = useTheme();
  const useUnderline = variant === ButtonVariant.Text && !cta;

  const noop = () => {};
  let renderedControl;

  // type is used in form.tsx for form submission. It needs to be a simple button, even though there is no "action" attached to it from form.tsx. Form walks through the item and adds additional functionality.
  if (type in InputType) {
    renderedControl = (
      <InnerButton
        onClick={!disabled ? onClick : noop}
        type={type === InputType.SUBMIT ? "submit" : null}
      >
        <ContainedText>{children}</ContainedText>
        {isLoading && (
          <LoadingPosition>
            <Loading
              size={28}
              borderWidth={3}
              borderColor={theme.Color.SpinnerOnDark}
            />
          </LoadingPosition>
        )}
      </InnerButton>
    );
  } else if (onClick && !href) {
    switch (variant) {
      case ButtonVariant.Text:
        renderedControl = (
          <ButtonAsText
            onClick={!disabled ? onClick : noop}
            $useUnderline={useUnderline}
          >
            {children}
          </ButtonAsText>
        );
        break;
      case ButtonVariant.Contained:
        renderedControl = (
          <InnerButton onClick={!disabled ? onClick : noop} $width={width}>
            <ContainedText>{children}</ContainedText>
          </InnerButton>
        );
        break;
      default:
        renderedControl = (
          <NoStylesButton onClick={!disabled ? onClick : noop}>
            {children}
          </NoStylesButton>
        );
    }
  }

  // Original element is a form component
  else if (action && !href) {
    switch (variant) {
      case ButtonVariant.Text:
        renderedControl = (
          <Form action={action}>
            <NoStylesButton>
              <Text $useUnderline={useUnderline}>{children}</Text>
            </NoStylesButton>
          </Form>
        );
        break;
      case ButtonVariant.ContainedIcon:
      case ButtonVariant.Icon:
      case ButtonVariant.GrowableIcon:
        renderedControl = (
          <Form action={action}>
            <NoStylesButton onClick={!disabled ? onClick : noop}>
              {children}
            </NoStylesButton>
          </Form>
        );
        break;
      default:
        renderedControl = (
          <FormStyled action={action}>
            <InnerButton onClick={!disabled ? onClick : noop} $width={width}>
              <ContainedText>{children}</ContainedText>
            </InnerButton>
          </FormStyled>
        );
    }
  } else {
    switch (variant) {
      case ButtonVariant.Icon:
      case ButtonVariant.GiantText:
        renderedControl = <>{children}</>;
        break;
      case ButtonVariant.Text:
        renderedControl = <Text $useUnderline={useUnderline}>{children}</Text>;
        break;
      case ButtonVariant.ContainedIcon:
      case ButtonVariant.GrowableIcon:
        renderedControl = children;
        break;
      default:
        renderedControl = <ContainedText>{children}</ContainedText>;
    }
  }

  // Render animations
  let controlAnimationProps;
  switch (variant) {
    case ButtonVariant.Text:
      controlAnimationProps = {
        changeColor: true,
        color: color
          ? color
          : cta
          ? theme.Color.ButtonColor
          : theme.Color.SecondaryColor,
        className: className,
      };
      break;
    case ButtonVariant.GiantText:
      controlAnimationProps = {
        changeColor: true,
        color: theme.Color.PrimaryColor,
        changeBgColor: true,
        bgColor: "rgba(255,255,255,0)",
      };
      break;
    case ButtonVariant.Icon:
      controlAnimationProps = controlAnimationProps = {
        changeColor: true,
        color: color || theme.Color.PrimaryColor,
        isActive: isActive,
        className: className,
        display: "flex",
      };
      break;
    case ButtonVariant.GrowableIcon:
      controlAnimationProps = {
        changeColor: true,
        color: color || theme.Color.ButtonColor,
        grow: 1.2,
        config: { mass: 1, tension: 2000, friction: 120 },
      };
      break;
    default:
      // Change the (text) color if the bgColor changes
      controlAnimationProps = {
        changeColor: changeBgColor,
        color: color
          ? color
          : bgColor
          ? bgColor && readableColor(bgColor)
          : cta
          ? theme.Color.ButtonTextColor
          : theme.Color.UnchangeableIconColor,
        className: className,
        changeBgColor: changeBgColor,
        bgColor: bgColor
          ? bgColor
          : cta
          ? theme.Color.ButtonColor
          : theme.Color.TransparentLight,
        grow: width && width < 100 ? 1.2 : 1.05,
      };
      break;
  }

  var alpha = bgColor && parseFloat(bgColor.split(",")[3]);
  let disabledOpacity = 1;
  if (disabled) {
    if (alpha && alpha < 1) {
      disabledOpacity = 0.5;
    } else {
      disabledOpacity = 0.3;
    }
  }

  let controlAnimation;
  switch (variant) {
    case ButtonVariant.Contained:
      controlAnimation = (
        <ContainedAnimationStyled
          {...controlAnimationProps}
          $disabledOpacity={disabledOpacity}
          $width={width}
        >
          {renderedControl}
        </ContainedAnimationStyled>
      );
      break;
    case ButtonVariant.ContainedIcon:
      controlAnimation = (
        <ContainedIconAnimation
          {...controlAnimationProps}
          disabled={disabled}
          $size={width}
        >
          {renderedControl}
        </ContainedIconAnimation>
      );
      break;
    default:
      controlAnimation = (
        <ControlAnimation {...controlAnimationProps} disabled={disabled}>
          {renderedControl}
        </ControlAnimation>
      );
      break;
  }

  if (!disabled) {
    if (typeof href === "string") {
      if (externalLink) {
        return (
          <A href={href} target="_blank" rel="noopener noreferrer">
            {controlAnimation}
          </A>
        );
      } else if (download) {
        return (
          <A href={href} download={download}>
            {controlAnimation}
          </A>
        );
      }
    }

    if (href) {
      return (
        <Link href={href} passHref>
          <A {...{ target, rel }}>{controlAnimation}</A>
        </Link>
      );
    }
  }

  return controlAnimation;
};

export default Button;

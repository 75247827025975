import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";

import useTheme from "../../hooks/use-theme";
import { useResponsive } from "../responsive-provider";

type ControlProps = {
  changeColor?: boolean;
  color?: string;
  bgColor?: string;
  changeBgColor?: boolean;
  grow?: number;
  children?: React.ReactNode;
  config?: any;
  className?: string;
  isActive?: boolean;
  disabled?: boolean;
  display?: string;
};
const ControlAnimation = ({
  changeColor,
  color: textColor,
  changeBgColor,
  bgColor,
  grow,
  children,
  config,
  className,
  isActive,
  disabled,
  display,
}: ControlProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicking, setIsClicking] = useState(false);
  const theme = useTheme();
  const { isTouchDevice } = useResponsive();

  useEffect(() => {
    if (disabled) {
      // There is an issue in how mouseleave events fire when a button is marked as disabled.
      // This makes sure we set our hover state to false when a button is disabled.
      setIsHovered(false);
    }
  }, [disabled]);

  let _backgroundColor = bgColor || theme.Color.TransparentLight;
  if (changeBgColor && isHovered) {
    _backgroundColor = theme.Color.ButtonHover;
  }

  let _color = textColor || theme.Color.PrimaryColor;
  if (changeColor && (isHovered || isActive)) {
    _color = changeBgColor
      ? theme.Color.ButtonHoverTextColor
      : theme.Color.LinkHoverTextColor;
  }

  let desiredScale;
  if (isClicking) {
    desiredScale = grow - 0.03;
  } else if (isHovered) {
    desiredScale = grow;
  } else {
    desiredScale = 1;
  }

  const { color, backgroundColor, scale } = useSpring({
    color: _color,
    backgroundColor: bgColor && _backgroundColor,
    scale: isHovered && grow ? desiredScale : 1,
    config: config || { mass: 1, tension: 150, friction: 14 },
  });

  const mouseEvents =
    isTouchDevice || disabled
      ? {}
      : {
          onMouseEnter: () => setIsHovered(true),
          onMouseLeave: () => {
            setIsHovered(false);
            setIsClicking(false);
          },
          onMouseDown: () => setIsClicking(true),
          onMouseUp: () => setIsClicking(false),
        };

  return (
    <animated.div
      {...mouseEvents}
      className={className}
      style={
        {
          backgroundColor: backgroundColor,
          color: color,
          transform: scale.to((s) => `scale(${s})`),
          cursor: disabled ? "initial" : "pointer",
          display: display || (!bgColor && "inline-block"),
        } as any
      }
    >
      {children}
    </animated.div>
  );
};

export default ControlAnimation;
